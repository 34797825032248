
let GetPrettyUrl = function(text) {;
    return ChangeDiacriticChars(text)
        .split(' ').join('-')
        .replace(/[^a-zA-Z0-9-_]/g, '');
}
exports.GetPrettyUrl = GetPrettyUrl;

function ChangeDiacriticChars(text) {
    return text
        .replace("ą", "a")
        .replace("ć", "c")
        .replace("ę", "e")
        .replace("ł", "l")
        .replace("ń", "n")
        .replace("ó", "o")
        .replace("ś", "s")
        .replace("ż", "z")
        .replace("ź", "z")
        .replace("Ą", "A")
        .replace("Ć", "C")
        .replace("Ę", "E")
        .replace("Ł", "L")
        .replace("Ń", "N")
        .replace("Ó", "O")
        .replace("Ś", "S")
        .replace("Ż", "Z")
        .replace("Ź", "Z")
}
