import React, { Component } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Img from 'gatsby-image'
import "./blog.scss"
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import { GetPrettyUrl } from '../helpers/text-helpers'

const Blog = ({ data }) => (
  <Layout>
    <section id="blog-slider">
      <Container>
        <h1>Blog o maskach antysmogowych</h1>
        <p>Dowiedz się więcej o <b>maskach przeciwpyłowych. </b>
           Jakie zagrożenia niesie poziom <b>smogu</b> w Polsce?<br />
           Poznaj polskiego producenta masek antysmogowych <b>Vaire.</b></p>
        <Row>
          {data.allStrapiArticle.edges.map(document => (
            <Col key={document.node.id} md="6">
              <div className="feature">
                <Link className="title" to={`/${GetPrettyUrl(document.node.title)}`}>
                  <Img className="border-radius"
                    fluid={document.node.image.localFile.childImageSharp.fluid}
                    alt={document.node.image.alternativeText}
                    objectFit="cover"
                    objectPosition="50% 50%" />
                </Link>
                <div className="text-container">
                  <h2>
                    <Link className="title" to={`/${GetPrettyUrl(document.node.title)}`}>{document.node.title}</Link>
                  </h2>
                  <p className="description">{document.node.excerpt ?? ''}</p>
                </div>
              </div>
              <Link to={`/${GetPrettyUrl(document.node.title)}`} className="more-posts">Czytaj wpis</Link>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  </Layout>
)

export default Blog

export const pageQuery = graphql`  
  query IndexQuery {
    allStrapiArticle {
      edges {
        node {
          id
          image {
            alternativeText
            localFile {
              childImageSharp {
                fluid(maxWidth: 640, quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          title
          content
          excerpt
        }
      }
    }
  }
`
